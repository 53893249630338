import UserAccess from "@/model/User/UserAccess";
import router from "@/router";
import routerIbotech from "@/router/routerIbotech";
import ibomadeUserService from "@/services/ibomadeUserService";
import ModelUser from "@/model/ModelUser";
import Vue from 'vue';
import ibotechService from "@/services/ibotechService";

export class CurrentUserState {
  currentUser: ModelUser = new ModelUser();
  isAdmin: boolean = false;
  isUserAgency: boolean = false;
  isProjectManager = false;
  isConstructionManager = false;
  isIbotech = false;
  isIbotechConstructionUser: boolean = false;
  isIbotechSignMeasurementAdmin: boolean = false;
  isIbotechSignMeasurementUser: boolean = false;

  access = new UserAccess();
}

const getters = {
  getCurrentUser(state: CurrentUserState): ModelUser {
    return state.currentUser;
  },
  isUserAdmin(state: CurrentUserState): boolean {
    return state.isAdmin;
  },
  isUserAgency(state: CurrentUserState): boolean {
    return state.isUserAgency;
  },
  isUserProjectManager(state: CurrentUserState): boolean {
    return state.isProjectManager;
  },
  isConstructionManager(state: CurrentUserState): boolean {
    return state.isConstructionManager;
  },
  isIbotech(state: CurrentUserState): boolean {
    return state.isIbotech;
  },
  isIbotechConstructionUser(state: CurrentUserState): boolean {
    return state.isIbotechConstructionUser;
  },
  isIbotechSignMeasurementAdmin(state: CurrentUserState): boolean {
    return state.isIbotechSignMeasurementAdmin;
  },
  isIbotechSignMeasurementUser(state: CurrentUserState): boolean {
    return state.isIbotechSignMeasurementUser;
  },
  getUserAccess(state: CurrentUserState) {
    return state.access;
  }
};
const mutations = {
  setCurrentUser(state: any, user: any) {
    state.currentUser = user;
  },
  resetUser(state: any) {
    if (state.currentUser !== null) {
      state.currentUser = null;
    }
  },
  setCurrentUserPermission(state: CurrentUserState, value: boolean) {
    state.isAdmin = value;
  },
  setCurrentUserPermissionManager(state: CurrentUserState, isUserAgency: boolean) {
    state.isUserAgency = isUserAgency;
  },
  setCurrentUserPermissionProjectManager(state: CurrentUserState, isProjectManager: boolean) {
    state.isProjectManager = isProjectManager;
  },
  setCurrentUserPermissionConstructionManager(state: CurrentUserState, isConstructionManager: boolean) {
    state.isConstructionManager = isConstructionManager;
  },
  setCurrentUserPermissionIbotech(state: CurrentUserState, isIbotech: boolean) {
    state.isIbotech = isIbotech;
  },
  setCurrentUserPermissionIbotechConstructionUser(state: CurrentUserState, isIbotechConstructionUser: boolean) {
    state.isIbotechConstructionUser = isIbotechConstructionUser;
  },
  setCurrentUserPermissionIbotechSignMeasurementAdmin(state: CurrentUserState, isIbotechSignMeasurementAdmin: boolean) {
    state.isIbotechSignMeasurementAdmin = isIbotechSignMeasurementAdmin;
  },
  setCurrentUserPermissionIbotechSignMeasurementUser(state: CurrentUserState, isIbotechSignMeasurementUser: boolean) {
    state.isIbotechSignMeasurementUser = isIbotechSignMeasurementUser;
  },
  setAccess(state: CurrentUserState, access: UserAccess) {
    state.access = access;
  }
};
const actions = {

  async getInfo({commit}: any, id: string) {
    const res = await ibotechService.getUserProfile(id);

    if (res.status === 200) {
      const user = new ModelUser(res.data);
      commit("setCurrentUser", user);
      return res;
    } else {
      commit("resetUser");
      return res;
    }

  },

  async getUserInfo({commit}: any, id: string) {
    const res = await ibomadeUserService.getUserProfile(id);

    if (res.status === 200) {
      const user = new ModelUser(res.data);
      commit("setCurrentUser", user);
      return res;
    } else {
      commit("resetUser");
      return res;
    }
  },
  resetUser({commit}: any) {
    commit("resetUser");
  },
  checkUserAdmin({commit}: any, root: string) {

    let isAdmin;
    let isUserAgency;
    let isProjectManager;
    let isConstructionManager;
    let isIbotech;
    let isIbotechConstructionUser;
    let isIbotechSignMeasurementAdmin;
    let isIbotechSignMeasurementUser;

    if(root == "ibotech") {
      isIbotech = (routerIbotech as any).app.$keycloak.hasRealmRole("ibotech");
      isIbotechConstructionUser = (routerIbotech as any).app.$keycloak.hasRealmRole("ibotech_construction_user");
      isIbotechSignMeasurementAdmin = (routerIbotech as any).app.$keycloak.hasRealmRole("ibotech_sign_measurement_admin");
      isIbotechSignMeasurementUser = (routerIbotech as any).app.$keycloak.hasRealmRole("ibotech_sign_measurement_user");

    }

    else {
      isAdmin = (router as any).app.$keycloak.hasRealmRole("admin");
      isUserAgency = (router as any).app.$keycloak.hasRealmRole("agency");
      isProjectManager = (router as any).app.$keycloak.hasRealmRole("project_manager");
      isConstructionManager = (router as any).app.$keycloak.hasRealmRole("construction_manager");
    }

    const userAccess = new UserAccess();

    if (isAdmin) {
      commit("setCurrentUserPermission", isAdmin);
      userAccess.setAgencyAccess();
      userAccess.setProjectManagerAccess();
      userAccess.setAdminAccess();
      userAccess.setConstructionManagerAccess();
    } else if (isProjectManager) {
      commit("setCurrentUserPermissionProjectManager", isProjectManager);
      userAccess.setAgencyAccess();
      userAccess.setProjectManagerAccess();
    } else if (isConstructionManager) {
      commit("setCurrentUserPermissionConstructionManager", isConstructionManager);
      userAccess.setAgencyAccess();
      userAccess.setConstructionManagerAccess();
    } else if (isUserAgency) {
      commit("setCurrentUserPermissionManager", isUserAgency);
      userAccess.setAgencyAccess();
    } else if(isIbotech) {
      commit("setCurrentUserPermissionIbotech", isIbotech);
      userAccess.setIbotechAccess();
    } else if (isIbotechConstructionUser) {
      commit("setCurrentUserPermissionIbotechConstructionUser", isIbotechConstructionUser);
      userAccess.setIbotechContructionUser();
    } else if(isIbotechSignMeasurementAdmin) {
      commit("setCurrentUserPermissionIbotechSignMeasurementAdmin", isIbotechSignMeasurementAdmin);
      userAccess.setCurrentUserPermissionIbotechSignMeasurementAdmin();
    } else if(isIbotechSignMeasurementUser) {
      commit("setCurrentUserPermissionIbotechSignMeasurementUser", isIbotechSignMeasurementUser);
      userAccess.setCurrentUserPermissionIbotechSignMeasurementUser();
    }
    commit("setAccess", userAccess);
  }
};

export default {
  state: new CurrentUserState(),
  getters,
  mutations,
  actions
};
