import { Device } from "@/interface/DeviceInterface";
import { DeviceProjectDetails, InventoryState } from "@/stores/modules/inventoryDevices";
import { AppState } from "@/stores";
import IbotechService from "@/services/ibotechService";
import { MutationTree, ActionTree, GetterTree, ActionContext } from "vuex";

export class IbotechModule {
  vehicleList: Array<object> = [];
  locationList: Array<object> = [];
  setVehicleRecordsList: Array<object> = [];
  setMissingRecordsList: Array<object> = [];

  // Sign Measurement Orders
  setOrdersList: Array<object> = [];
  setTotalOrdersList: any = 0;

  // Sign Measurement Orders Batch
  setOrdersBatchList: Array<object> = [];
  setTotalOrdersBatchList: any = 0;

  error: any;
}

const getters: GetterTree<IbotechModule, AppState> = {
  getAllVehiclesData(state: IbotechModule) {
    return state.vehicleList;
  },
  getAllLocationsData(state: IbotechModule) {
    return state.locationList;
  },
  getAllRecordsData(state: IbotechModule) {
    return state.setVehicleRecordsList;
  },
  getMissingRecordsData(state: IbotechModule) {
    return state.setMissingRecordsList;
  },
  getOrdersData(state: IbotechModule) {
    return state.setOrdersList;
  },
  getTotalOrdersList(state: IbotechModule) {
    return state.setTotalOrdersList;
  },
  getOrdersBatchData(state: IbotechModule) {
    return state.setOrdersBatchList;
  },
  getTotalOrdersBatchList(state: IbotechModule) {
    return state.setTotalOrdersBatchList;
  }
}


const actions: ActionTree<IbotechModule, AppState> = {
  async getAllVehiclesFun({ commit }: ActionContext<IbotechModule, AppState>) {
    try {
      const res = await IbotechService.getAllVehicles();
      if (res.status === 200) {
        const list = res.data;
        commit("setVehicleList", list);
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },

  async getVehicleRecordsByDate({ commit }: ActionContext<IbotechModule, AppState>, data) {
    try {
      const res = await IbotechService.getVehicleRecordsByDate(data);
      if (res.status === 200) {
        const list = res.data;
        commit("setVehicleRecordsList", list);
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },

  async getAllLocations({ commit }: ActionContext<IbotechModule, AppState>) {
    try {
      const res = await IbotechService.getAllLocations();
      if(res.status === 200) {
        const list = res.data;
        commit("setLocationList", list);
      }
    } catch (error) {
      return error;
    }
  },

  async getMissingRecords({ commit }: ActionContext<IbotechModule, AppState>) {
    try {
      const res = await IbotechService.getMissingRecords();
      if(res.status === 200) {
        const list = res.data;
        commit("setMissingRecordsList", list);
      }
    } catch (error) {
      return error;
    }
  },


  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------
  // Order Sign Measurement Module
  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------

  async getAllOrders({ commit }: ActionContext<IbotechModule, AppState>, data: any) {
    try {
      let res: any = {};

      if(data) {
        res = await IbotechService.searchOrders(data);
      } else {
        res = await IbotechService.getAllOrders();
      }

      if(res.status === 200) {
        const list = res.data.orders;
        commit("setOrdersList", list);
        commit("setTotalOrdersList", res.data.totalCount);
      }
    } catch (error) {
      return error;
    }
  },

  async getAllOrdersSearch({ commit }: ActionContext<IbotechModule, AppState>, data: any) {
    try {

      const res = await IbotechService.searchOrders(data);

      if(res.status === 200) {
        const list = res.data.orders;
        commit("setOrdersList", list);
        commit("setTotalOrdersList", res.data.totalCount);
      }
    } catch (error) {
      return error;
    }
  },


  async getAllOrdersBatch({ commit }: ActionContext<IbotechModule, AppState>) {
    try {

      const res = await IbotechService.getAllBatches();

      if(res.status === 200) {

        const list = res.data.ordersBatch;
        commit("setOrdersBatchList", list);
        commit("setTotalOrdersBatchList", res.data.totalCount);
      }
    } catch (error) {
      return error;
    }
  },



}

const mutations: MutationTree<IbotechModule> = {
  setVehicleList(state: IbotechModule, listOfVehicles: Array<any>) {
    state.vehicleList = listOfVehicles;
  },
  setLocationList(state: IbotechModule, listOfLocations: Array<any>) {
    state.locationList = listOfLocations;
  },
  setVehicleRecordsList(state: IbotechModule, listOfRecords: Array<any>) {
    state.setVehicleRecordsList = listOfRecords;
  },
  setMissingRecordsList(state: IbotechModule, listOfRecords: Array<any>) {
    state.setMissingRecordsList = listOfRecords;
  },

  setOrdersList(state: IbotechModule, listOfOrders: Array<any>) {
    state.setOrdersList = listOfOrders;
  },

  setTotalOrdersList(state: IbotechModule, totalOrdersList: number) {
    state.setTotalOrdersList = totalOrdersList;
  },

  setOrdersBatchList(state: IbotechModule, listOfOrdersBatch: Array<any>) {
    state.setOrdersBatchList = listOfOrdersBatch;
  },

  setTotalOrdersBatchList(state: IbotechModule, totalOrdersBatchList: number) {
    state.setTotalOrdersBatchList = totalOrdersBatchList;
  },



}

export default {
  state: new IbotechModule(),
  getters,
  mutations,
  actions
};
